import type PartySocket from "partysocket"
import { useEffect, useState } from "react"
import { ChatRoom } from "./components/ChatRoom"
import CommentSection from "./components/CommentSection"
import styles from "./ObserverClient.module.css"
import sharedStyles from "./shared.module.css"
import type { ServerToClientMessage } from "../party/shared"

export const ObserverClient: React.FC<{ socket: PartySocket }> = ({ socket }) => {

  const [comments, setComments] = useState<string[]>([]);

  useEffect(() => {

    const onMessage = (evt: MessageEvent) => {

      const data = JSON.parse(evt.data) as ServerToClientMessage

      if (data.type === "newComment") {

        setComments(data.comments)

      }

      // Removed this, to keep the previous conversation displayed while the new one starts
      // Both messages & comments of the new conversation should display once the conversatoin
      // Reaches two messages long.

      if (data.type === "conversationUpdate") {

        // Slightly hacky way to reset comments when a new conversation has started
        // Would be better to have a dedicated even when a new conversation has started
        if (data.conversation.length === 2) {
          setComments([])
        }

      }

    }

    socket.addEventListener("message", onMessage)

    return () => {
      socket.removeEventListener("message", onMessage)
    }

  }, [socket])

  return <>
    <main >
      <div className={styles.observerContainer + " " + sharedStyles.shadowBox}>
        <div className={styles.lightbox}>
          <ChatRoom socket={socket} />
        </div>
        <CommentSection comments={comments} />
      </div>
    </main >
  </>

}