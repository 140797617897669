import type { NextOption } from "../../party/shared"
import { Card } from "./card/Card"
import { TutorialCard } from "./card/TutorialCard"
import type { SwipeScene } from "./game"
import { EventBus } from "./EventBus";

type DeckLockReason = "init" | "deal" | "shuffle" | "swipeLeft" | "swipeRight" | "moveStackIntoPosition"

const padding = 0.28
const cardsInStack = 4

/**
 * A lot of logic here was adapted from the previous "Swipe Game" I had
 * constructed in my day job.
 * Cards can be swiped left or right, and the rest of the stack moves
 * down to compensate.
 */

export class Deck {

  private deadCards: Set<Card> = new Set()
  private stack: Card[] = []

  private rejectedOptions: NextOption[] = []
  private unusedOptions: NextOption[] = []

  private activated = false
  private _lockSet: Set<DeckLockReason> = new Set()
  private _unlockCallbacks: Map<DeckLockReason, (() => void)[]> = new Map()

  public get activeCard(): Card | undefined {

    return this.stack[0]

  }

  constructor(private scene: SwipeScene, private selectOption: (option: NextOption) => void) {

    this.addLock("init", () => { this.moveStackIntoPosition() })

  }

  onCreate() {

    const tutorialCard = new TutorialCard(this.scene, {
      x: this.scene.cameras.main.width * 0.5,
      y: this.scene.cameras.main.height * 0.5,
      padding
    })

    this.scene.add.existing(tutorialCard)

    tutorialCard.activate(() => {
      this.activate()
      EventBus.emit('onboarding-complete')
    })

  }

  activate() {

    if (this.activated) return

    this.removeLock("init")
    this.activated = true

  }

  private addLock(reason: DeckLockReason, callback?: () => void) {

    /*
     * A lock is a set of strings that represent the reasons why the deck is locked.
     * If the set is empty, then the deck is unlocked and can be interacted with.
     * When adding a lock, you can also add a callback that will be called when the lock is removed.
     */

    this._lockSet.add(reason)

    /*
     * As a bandaid fix to a bug,
     * I am now only allowing max 1 callback per lock reason
     */

    if (callback) {
      this._unlockCallbacks.set(reason, [callback])
    }

  }

  private removeLock(reason: DeckLockReason) {

    /**
     * Remove lock and call any callbacks that were added when the lock was set.
     */

    this._lockSet.delete(reason)

    const callbacks = this._unlockCallbacks.get(reason) || []

    callbacks.forEach(callback => callback())

    this._unlockCallbacks.delete(reason)

  }

  get isLocked() {
    return this._lockSet.size > 0
  }

  get wiggle() {
    return Phaser.Math.Between(
      -this.cardYSpacing * 0.23,
      this.cardYSpacing * 0.23
    )
  }

  private get activeCardTargetPosition(): { x: number, y: number } {

    /**
     * Get the position that the active card should be in when it is in the "ready to swipe" position
     */

    const pos = 0.5 + padding * 0.25

    const height = this.scene.cameras?.main?.height

    const x = this.scene.cameras?.main?.width ? this.scene.cameras.main.width * 0.5 : 300
    const y = height ? height * pos : 400

    return { x, y }

  }

  private get cardYSpacing(): number {

    /**
     * Space cards out on Y axis to create a "stack" effect
     */

    const spacing = padding / Math.max(cardsInStack - 2, 1)

    const height = this.scene.cameras?.main?.height

    return height ? height * spacing : 20

  }

  private getCard(): Card {

    /**
     * Get a card from the dead cards set, or create a new one if the set is empty
     * A way to recycle cards and reduce garbage collection
     */

    const getDeadCard = this.deadCards.values().next()

    if (getDeadCard.done === false) {

      const card = getDeadCard.value

      this.deadCards.delete(card)

      return card

    } else {

      // The set was empty
      return new Card(this.scene, { padding })

    }

  }

  addOptions(options: NextOption[], moveStackIntoPosition: boolean) {

    /**
     * This is where we take in options provided by the LLM and add them to the deck
     * We add them to the unused options pile, ready to be used, and then shuffle it
     */

    this.unusedOptions.push(...options)
    this.unusedOptions.sort(() => Math.random() - 0.5)

    if (this.activated && moveStackIntoPosition) {

      this.moveStackIntoPosition()

    }

  }

  rejectOption(option: NextOption) {

    /**
     * Reject an option -- but maybe add it back to the stack in case we need to reuse options
     * IF the user rejects the whole stack, we cycle back from the beginning
     */

    this.rejectedOptions.push(option)

    if (this.rejectedOptions.length >= this.stack.length) {

      // Shuffle the rejected options and add half of them back to the stack
      this.rejectedOptions.sort(() => Math.random() - 0.5)

      const easier = this.rejectedOptions
        .splice(0, Math.ceil(this.rejectedOptions.length / 2))
        // we also make them easier next time around
        .map(([token, logprop]) => ([token, logprop * 0.75] as NextOption))

      this.addOptions(
        easier,
        false
      )
    }

  }

  moveStackIntoPosition(callback?: () => void) {

    /**
     * Move the stack into position, and deal new cards if necessary
     */

    if (this.stack.length < cardsInStack) {
      for (let i = 0; i <= (cardsInStack - this.stack.length); i++) {

        const option = this.unusedOptions.shift()

        if (option === undefined) {
          console.error("No more options to deal")
          return
        }

        const card = this.getCard()

        card
          .setOption(option)
          .getReadyForDeal()

        this.stack.push(card)

      }
    }

    this.addLock("moveStackIntoPosition", () => {

      if (this.activeCard === undefined) {
        console.error("Active card is undefined at end of moveStackIntoPosition")
        // This shouldn't happen, but it did once and it broke things,
        // The best I can manage is to just try again I guess?
        this.moveStackIntoPosition(callback)
        return;
      }

      /**
       * Once the stack is in position, and this callback is called, we activate the cursor
       * and give it a callback to swipe right on success, or left on failure
       */

      this.activeCard.activateCursor({
        onSuccess: () => {
          this.swipeRight()
        },
        onFailure: () => {
          this.swipeLeft()
        }
      })

      callback?.()

    })

    /**
     * Now, for each card in the stack,
     * move it into a position
     * that is offset by its position in the stack
     * to create a pile of cards that moves one step at a time
     */
    this.stack.forEach((card, index, stack) => {

      card
        .setVisible(true)
        .setDepth(-index)

      this.scene.tweens.add({
        targets: [card],
        x: this.activeCardTargetPosition.x + (this.wiggle * (1 - index / cardsInStack)),
        y: this.activeCardTargetPosition.y - index * this.cardYSpacing + (this.wiggle * (1 - index / cardsInStack)),
        delay: Phaser.Math.Between(80, 120) * index,
        duration: Phaser.Math.Between(900, 1100),
        ease: 'Power2',
        onComplete: () => {
          if (index === 0) {
            // run callback as soon as the first card is in position
            this.removeLock("moveStackIntoPosition")
            this.removeLock("swipeLeft")
            this.removeLock("swipeRight")
          }
        }
      })

    })

  }


  swipeLeft() {

    /**
     * Animate left and reject the option
     */

    this.addLock("swipeLeft")

    const firstCard = this.stack.shift()

    if (firstCard === undefined) { this.removeLock("swipeLeft"); return false }

    this.rejectOption(firstCard.option)

    firstCard.swipeLeft(card => {
      this.deadCards.add(card)
    })

    this.moveStackIntoPosition(() => {

      this.removeLock("swipeLeft")

    })

    return true

  }

  swipeRight() {

    /**
     * Animate right and accept the option
     */

    this.addLock("swipeRight")

    // Remove the card from the stack and then swipe it right
    const firstCard = this.stack.shift()

    if (firstCard === undefined) { this.removeLock("swipeRight"); return false }

    firstCard
      .setDepth(100)
      .swipeRight(card => {
      this.deadCards.add(card)
    })

    // Swipe the remianing cards left
    this.stack.forEach((card, index) => {
      card
        .setDepth(100 - index)
        .swipeLeft(card => this.deadCards.add(card), { delay: index * 3 * (0.5 + Math.random()) })
    })

    // Reset the stack
    this.unusedOptions.length = 0
    this.rejectedOptions.length = 0
    this.stack.length = 0

    this.selectOption(firstCard.option)

    return true

  }

}