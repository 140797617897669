.observerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 48rem;
  max-width: 70rem;
  height: 95vh;
}

.lightbox {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  font-size: x-large;
  padding: 5em;
}