.messageBox {
  border: 1px solid rgb(214, 211, 209);
  background-color: white;
  padding: 0rem 0.3rem;
  width: 100%;
  padding: 0.25rem 0.67rem;
  height: 2.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 1rem;
}

.current-message {
  border: 0;
}

.current-message.inactive {
  color: red;
}