import p5 from "p5"
import React, { useEffect, useRef } from "react"
import { getSketch, type SketchProps } from "./sketch"
import styles from "./P5View.module.css"

export const P5View: React.FC<SketchProps> = (props) => {

  /**
   * A simple wrapper to get p5.js into React
   */

  const myRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (myRef.current === null) { return }
    new p5(getSketch(props), myRef.current)
  }, [props.setMouthIsOpen, props.setMouthIsVisible])

  return (
    <div className={styles.p5View} ref={myRef} />
  )

}