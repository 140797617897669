import { FaceLandmarker, type FaceLandmarkerResult, FilesetResolver } from "@mediapipe/tasks-vision";
import p5 from 'p5';

let faceLandmarker: FaceLandmarker;

async function createFaceLandmarker() {
  const filesetResolver = await FilesetResolver.forVisionTasks(
    "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm"
  );
  const faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
    baseOptions: {
      modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
      delegate: "GPU"
    },
    runningMode: "VIDEO",
    numFaces: 1
  })
  return faceLandmarker;
}

// I had to manually check these T_T
// https://storage.googleapis.com/mediapipe-assets/documentation/mediapipe_face_landmark_fullsize.png
const mouthIndices = {
  inner: [13, 82, 81, 80, 191, 78, 95, 88, 178, 87, 14, 317, 402, 318, 324, 308, 415, 310, 311, 312],
  outer: [0, 37, 39, 40, 185, 62, 146, 91, 181, 84, 17, 314, 405, 321, 375, 291, 409, 270, 269, 267],
  innerTop: 13,
  innerBottom: 14,
  innerLeft: 78,
  innerRight: 308,
}

createFaceLandmarker().then((fl) => {
  faceLandmarker = fl;
})

export interface SketchGlobalParams {
  video: p5.Element & { flipped?: boolean },
  results: FaceLandmarkerResult | null,
  lastVideoTime: number,
}

export type SketchProps = {
  setMouthIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
  setMouthIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
}


export const getSketch = ({ setMouthIsOpen, setMouthIsVisible }: SketchProps) => {

  /**
   * This p5 sketch is purely here to get mediapipe up and running and to keep track
   * of whether the mouth is visible, and whether it is open.
   * If the mouth is no longer visible, we set a timeout to "raise the alarm" (notify React) after five seconds"
   */

  let mouthIsVisible = false
  let raiseTheMouthAlarm: ReturnType<typeof setTimeout> | null = null

  function processMouthVisibility(isVisible: boolean) {

    if (isVisible === mouthIsVisible) { return }

    mouthIsVisible = isVisible

    if (isVisible) {

      setMouthIsVisible(true)

      if (raiseTheMouthAlarm) {
        clearTimeout(raiseTheMouthAlarm)
        raiseTheMouthAlarm = null
      }

    }

    if (isVisible === false) {

      raiseTheMouthAlarm = setTimeout(() => {
        setMouthIsVisible(false)
      }, 5000)

    }

  }

  return (p: p5) => {

    let g: SketchGlobalParams;

    let lastVideoTime = 0;

    /**
     * Update the mouth tracking every frame
     */

    function updateResults(g: SketchGlobalParams) {

      if (faceLandmarker && g.video && g.video.elt && g.video.elt.currentTime !== lastVideoTime) {

        const videoElement = g.video.elt;

        const results = faceLandmarker.detectForVideo(videoElement, videoElement.currentTime * 1000);
        lastVideoTime = videoElement.currentTime

        g.results = results;

        if (results && results.faceLandmarks.length !== 0) {

          processMouthVisibility(true)

          const mouthPoints = {
            top: results.faceLandmarks[0][mouthIndices.innerTop],
            left: results.faceLandmarks[0][mouthIndices.innerLeft],
            bottom: results.faceLandmarks[0][mouthIndices.innerBottom],
            right: results.faceLandmarks[0][mouthIndices.innerRight],
          }

          const mouthHeight = p.dist(
            mouthPoints.top.x,
            mouthPoints.top.y,
            mouthPoints.bottom.x,
            mouthPoints.bottom.y
          )

          const mouthWidth = p.dist(
            mouthPoints.left.x,
            mouthPoints.left.y,
            mouthPoints.right.x,
            mouthPoints.right.y
          )

          const aspectRatio = mouthWidth / (mouthHeight || 0.0001)

          const mouthOpenThreshold = 3.0

          setMouthIsOpen(aspectRatio <= mouthOpenThreshold)

        } else {

          processMouthVisibility(false)

        }

      }

    }

    p.setup = () => {

      // Decrease the frame rate to decrease the frequency of mouth detection, to improve overall performance (on mobile)
      p.frameRate(10)

      const cnv = p.createCanvas(480, 360)
      cnv.parent('app')
      cnv.style('display', 'none')

      const video: SketchGlobalParams["video"] = p.createCapture("video", () => {

        video.flipped = true
        video.hide()

      });

      g = {
        video,
        results: null,
        lastVideoTime,
      }

    }

    p.draw = () => {

      updateResults(g)

    }

  }

}