.adminWrapper {
  display: flex;
  flex-direction: column;
  background-color: 'red';
  justify-content: center;
  align-items: center;
  min-width: 50%;
  height: 100vh;
  vertical-align: middle;
  padding: 2em;
  text-align: center;
}

.adminPanel {
  vertical-align: middle;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
}

.adminButton {
  text-align: center;
  display: inline-block;
  cursor: pointer;
  border-radius: 1em;
  margin: 1em;
  flex-grow: 1;
  padding: 2em;
  width: 100%;
  max-height: 5em;
}

.withPassword {
  background-color: red;
}

.noPassword {
  background-color: orange;
}