import usePartySocket from "partysocket/react";
import { createRoot } from "react-dom/client";
import { ObserverClient } from "./ObserverClient";
import "./styles.css";
import { SwiperClient } from "./SwiperClient";
import { AdminClient } from "./AdminClient";
import { useEffect } from "react";
import type { ServerToClientMessage } from "../party/shared";

function App() {

  const socket = usePartySocket({ room: "default-room" });

  // Check for userId query parameter
  const params = new URLSearchParams(window.location.search);
  const userId = params.get('userId');

  useEffect(() => {

    const onMessage = (evt: MessageEvent) => {

      const data = JSON.parse(evt.data) as ServerToClientMessage

      console.log(data)

      if (data.type === "adminReset" && userId !== 'admin') {
        window.location.reload();
      }

    }

    socket.addEventListener("message", onMessage)

    return () => {
      socket.removeEventListener("message", onMessage)
    }

  }, [socket])

  return <>
    {
      userId === 'admin'
        ? <AdminClient socket={socket} />
        : (window.innerWidth < window.innerHeight)
          /* A kludgy way to allow different views on mobile vs desktop */
          ? <SwiperClient socket={socket} />
          : <ObserverClient socket={socket} />
    }
  </>
}

createRoot(document.getElementById("app")!).render(<App />);