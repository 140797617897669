import type { NextOption } from "../../../party/shared"
import { serifFont, type SwipeScene } from "../game"

export type CardParams = {
  x?: number
  y?: number
  option?: NextOption,
  padding: number,
}

function mapLogProbTo01(x: number) {
  // The probability of a token varies from (in theory) zero to negative infinity
  // But in practice it's more like -0.1 to -10
  // I wanted to map this to zero to 1, so
  // mucked around on desmos to come up with a difficulty curve I liked
  const a = 0.5 * x - 1
  const b = -Math.E * a * Math.exp(a)
  return b
}

const mouthOpenFace = "😮"
const mouthClosedFace = "😐"
const mouthOpenSuccessFace = "😍"
const mouthOpenFailureFace = "😵"

const trimColour = 0x121234
const secondaryTrimColour = 0xededed
const redColour = 0x682960
const greenColour = 0x66bb6a

export class Card extends Phaser.GameObjects.Container {

  protected rect: Phaser.GameObjects.Rectangle
  protected cardText: Phaser.GameObjects.Text
  protected firstLine: Phaser.GameObjects.Rectangle
  private lineBG: Phaser.GameObjects.Rectangle
  public cursor: Phaser.GameObjects.Text
  private cursorMarkers: Phaser.GameObjects.Text[] = []

  protected readonly trimColour = trimColour

  private cursorSpeed = 1.0;
  private cursorPerceivedDelay = 1 / 24;
  private cursorIsActive = false

  private yPositionAtCursorActivation = 0

  private success: {
    open: boolean | null,
  } = {
      open: null,
    }

  public alive: boolean = true

  private _option: NextOption = null!
  private easinessScore: number = 0.5

  constructor(public scene: SwipeScene, p: CardParams = { padding: 0.25 }) {

    const trimColourString = trimColour.toString(16)

    const cardHeight = scene.renderer.height * (1 - p.padding)
    const cardWidth = cardHeight * 0.78

    const lineHeight = cardHeight * 0.035
    const lineY = cardHeight * 0.075

    const rect = scene.add.rectangle(0, 0, cardWidth, cardHeight, 0xffffff, 1.0)
      .setOrigin(0.5, 0.5)
      .setStrokeStyle(2, trimColour, 1)

    const cardText = scene.add.text(0, -50, "", {
      fontFamily: serifFont,
      fontSize: cardHeight * 0.1 + 'px',
      color: '#' + trimColourString,
      // fontStyle: "bold",
      align: "center"
    })
      .setOrigin(0.5, 0.5)

    const firstLine = scene.add.rectangle(0, lineY, 0, lineHeight, redColour, 1.0)
      .setOrigin(0.5, 0.5)
      .setDepth(0)

    const lineBG = scene.add.rectangle(0, lineY, cardWidth, lineHeight - 1, secondaryTrimColour, 1.0)
      .setOrigin(0.5, 0.5)
      .setDepth(-1)
      .setStrokeStyle(1, trimColour, 1)

    const cursor = scene.add.text(
      -cardWidth / 2,
      lineY,
      mouthClosedFace,
      {
        fontSize: '2.5rem',
        testString: mouthClosedFace,
      },
    )
      .setOrigin(0.5, 0.5)
      .setDepth(10)
      .setVisible(false)

    super(scene, p.x ?? 0, p.y ?? 0, [rect, cardText, lineBG, firstLine, cursor])

    this.rect = rect

    this.cardText = cardText
    this.firstLine = firstLine
    this.lineBG = lineBG
    this.cursor = cursor

    this.scene.add.existing(this)

    this.setOption(p.option ?? ["", -Infinity])

    this.scene.game.events.on("mouth-change", (mouthOpened: boolean) => {
      if (mouthOpened) {
        this.onMouthOpened()
      } else {
        this.onMouthClosed()
      }
    })

  }

  public get option() {
    return this._option
  }

  public get token() {
    return this.option[0]
  }

  setOption(option: NextOption) {

    this._option = option
    this.updateText()
    this.updateDifficulty()

    return this

  }

  public getReadyForDeal() {

    /**
     * Reset a card and position it offscreen,
     * ready to be animated on as part of a deal
     */

    this
      .setRotation(0)
      .setPosition(this.scene.cameras.main.width * 0.5, - this.scene.cameras.main.height)
      .setDepth(0)
      .setScale(1)
      .setVisible(false)

    this.resetCursor()

    return this

  }

  // Reset the emoji 'cursor'

  protected resetCursor() {

    this.cursor.setText(mouthClosedFace)
    this.cursor.setDepth(10)

    this.cursorMarkers.forEach(marker => marker.destroy())
    this.cursorMarkers.length = 0

    this.success.open = null

    this.firstLine.setFillStyle(redColour, 1.0)

  }

  // Activate the cursor and animate it across the card

  public activateCursor({ onSuccess, onFailure, delay }: { onSuccess: () => void, onFailure: () => void, delay?: number }) {

    const startPos = this.lineBG.getLeftCenter()
    const endPos = this.lineBG.getRightCenter()

    this.yPositionAtCursorActivation = this.y

    // We use the actual position/animation of the cursor to determine success/failure when mouth is opened
    this.scene.tweens.add({
      targets: [this.cursor],
      delay: delay ?? 0,
      x: endPos.x,
      duration: 1000 / this.cursorSpeed,
      onStart: (tween, [cursor]) => {
        cursor.setPosition(startPos.x, startPos.y)
        cursor.setVisible(true)
        this.cursorIsActive = true
      },
      onComplete: () => {

        this.deactivateCursor()

        if (this.success.open === true) {
          onSuccess()
        } else {
          onFailure()
        }
      }
    })

    return this

  }

  private deactivateCursor() {

    this.cursorIsActive = false
    this.cursor.setVisible(false)

    return this

  }

  private updateText() {
    this.cardText.setText(this.token)
  }

  private updateDifficulty() {

    this.easinessScore = mapLogProbTo01(this.option[1])

    // Resize bar based on difficulty
    const minWidth = this.lineBG.displayWidth * 0.111
    const maxWidth = this.lineBG.displayWidth * 0.5

    const width = minWidth + (maxWidth - minWidth) * this.easinessScore

    this.firstLine.width = width
    this.firstLine.setPosition(0, this.lineBG.y)
    this.firstLine.setOrigin(0.5, 0.5)

  }

  // Animate left when rejected
  swipeLeft(callback: (card: Card) => void, tweenParams: Partial<Parameters<typeof this.scene.tweens.add>[0]> = {}) {

    this.scene.tweens.add({
      targets: this,
      x: this.x - this.scene.cameras.main.width * 1.5,
      angle: 'random(-15, -25)',
      duration: 400 + Math.random() * 200,
      ease: Phaser.Math.Easing.Back.In,
      onComplete: () => {

        this.alive = false
        this.getReadyForDeal()
        callback(this)

      },
      ...tweenParams
    })

  }

  // Animate right when accepted
  swipeRight(callback: (card: Card) => void, tweenParams: Partial<Parameters<typeof this.scene.tweens.add>[0]> = {}) {

    this.scene.tweens.add({
      targets: this,
      x: this.x + this.scene.cameras.main.width * 1.5,
      angle: 'random(15, 25)',
      duration: 400 + Math.random() * 200,
      ease: Phaser.Math.Easing.Back.In,
      onComplete: () => {
        this.alive = false
        this.getReadyForDeal()
        callback(this)
      },
      ...tweenParams
    })

  }

  onMouthOpened() {

    /**
     * Determine success based on whether the cursor is in the "hotzone" when the mouth is opened
     */


    if (this.cursorIsActive == false) { return }

    this.cursor.setText(mouthOpenFace)

    // There's a perceived delay on detecting mouth open, so we compensate here
    const perceivedX = Math.max(
      this.cursor.x - this.cursorSpeed * this.cursorPerceivedDelay * this.lineBG.displayWidth * this.scene.tweens.getGlobalTimeScale(),
      this.lineBG.getBottomLeft().x
    )

    // They only get one shot at success!
    if (this.success.open !== null) { return }

    if (
      this.success.open === null
      && perceivedX >= this.firstLine.getTopLeft().x
      && perceivedX <= this.firstLine.getTopRight().x
    ) {
      this.onMouthOpenedSuccess(perceivedX)
    } else {
      this.onMouthOpenedFailure(perceivedX)
    }

  }

  onMouthClosed() {

    if (this.cursorIsActive === false) { return }

    this.cursor.setText(mouthClosedFace)

  }

  onMouthOpenedSuccess(x: number) {

    // Place a 'success' smiley, and add a particle effect

    this.success.open = true

    this.firstLine.setFillStyle(greenColour, 1.0)

    const newSmiley = this.scene.add.text(
      x,
      this.cursor.y,
      mouthOpenSuccessFace,
      {
        fontSize: '2.5rem',
        testString: mouthOpenSuccessFace,
      },
    )
      .setOrigin(0.5, 0.5)
      .setDepth(5)

    this.cursorMarkers.push(newSmiley)
    this.add(newSmiley)

    addEmojiParticle(this, this.x + this.cursor.x, this.y + this.cursor.y, "❤️")

    this.scene.tweens.add({

      targets: this,
      y: this.yPositionAtCursorActivation - this.scene.cameras.main.height * 0.05,
      duration: 200,
      ease: Phaser.Math.Easing.Cubic.InOut,
      scale: 1.2

    })

  }

  onMouthOpenedFailure(x: number) {

    // Add a failure emoji

    this.success.open = false
    addEmojiParticle(this, this.x + this.cursor.x, this.y + this.cursor.y, "💔")

    const newSmiley = this.scene.add.text(
      x,
      this.cursor.y,
      mouthOpenFailureFace,
      {
        fontSize: '2.5rem',
        testString: mouthOpenSuccessFace,
      },
    )
      .setOrigin(0.5, 0.5)
      .setDepth(5)

    this.cursorMarkers.push(newSmiley)
    this.add(newSmiley)

    this.scene.tweens.add({

      targets: this,
      y: this.yPositionAtCursorActivation,
      duration: 200,
      ease: Phaser.Math.Easing.Cubic.InOut,
      scale: 1.0

    })

  }

  onTotalSuccess() {

    // On success, add a little 'pop' animation to indicate succes

    this.setDepth(100)

    this.scene.tweens.add({

      targets: this,
      y: this.yPositionAtCursorActivation - this.scene.cameras.main.height * 0.15,
      duration: 200,
      ease: Phaser.Math.Easing.Cubic.InOut,
      scale: 1.2

    })

  }

}

// Animate a text emoji in Phaser

function addEmojiParticle(card: Card, x: number, y: number, emoji: string) {

  x = x + Phaser.Math.FloatBetween(-1.4, 1.4) * card.scene.cameras.main.width * 0.02
  y = y - Phaser.Math.FloatBetween(-1.4, 1.4) * card.scene.cameras.main.height * 0.02

  const particle = card.scene.add.text(
    x,
    y,
    emoji,
    {
      fontSize: '7rem',
      testString: emoji,
    })
    .setOrigin(0.5, 1)
    .setScale(0.1)
    .setAlpha(0.8)
    .setDepth(500)
    .setVisible(false)

  card.scene.tweens.add({
    targets: particle,
    x: x + Phaser.Math.FloatBetween(-1.4, 1.4) * card.scene.cameras.main.width * 0.03,
    y: y - Phaser.Math.FloatBetween(0.13, 0.28) * card.scene.cameras.main.height,
    scale: 1.0 * Phaser.Math.FloatBetween(0.5, 1.0),
    duration: 667 * Phaser.Math.FloatBetween(0.75, 1.8),
    delay: 100,
    ease: Phaser.Math.Easing.Cubic.Out,
    onStart: () => {
      particle.setVisible(true)
    },
    onComplete: () => {
      card.scene.tweens.add({
        targets: particle,
        duration: 333 * Phaser.Math.FloatBetween(0.75, 1.8),
        scale: 0.001,
        alpha: 0.001,
        ease: Phaser.Math.Easing.Cubic.In,
        onComplete: () => {
          particle.destroy()
        }
      })
    }
  })

}