.outer {
  flex-grow: 1;
  width: 100%;
  flex-basis: 100%;
  position: relative;
  align-self: center;
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 0.4rem;
}

.inner {
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  padding: 0.5rem 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}

.listItem {
  display: flex;
  justify-content: start;
  align-items: end;
  /* gap: 0.5rem; */
  margin: 0.15em;
}

.listItem.reversed {
  justify-content: end;
  flex-direction: row-reverse;
}

.from {
  /* font-weight: 600; */
  /* background-color: red; */
  width: 0.5rem;
  top: 50%;
  left: -10px;
  border-width: 10px;
  border-style: solid;
  border-radius: 1;
  border-color: transparent #ededed #ededed transparent;
  /* transform: translateY(-50%); */
}

.message {
  position: relative;
  border-radius: 0.5em 0.5em 0.5em 0;
  background-color: #ededed;
  padding: 0.25em 0.5em;
  max-width: 80%;
}

.listItem.reversed .message {
  color: #ffffff;
  background-color: #682960;
  border-radius: 0.5em 0.5em 0 0.5em;
}

.listItem.reversed .from {
  left: auto;
  right: -10px;
  border-color: transparent transparent #682960 #682960;
}