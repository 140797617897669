import type PartySocket from "partysocket";
import { useEffect, useState } from "react";
import {
  type Message,
  type ServerToClientMessage
} from "../../party/shared";
import styles from "./ChatRoom.module.css";
import MessageList from "./MessageList";

export const ChatRoom: React.FC<{ socket: PartySocket, userId?: string }> = ({ socket, userId }) => {

  const [messages, setMessages] = useState<Message[]>([]);
  const [firstUser, setFirstUser] = useState<string>("");

  useEffect(() => {

    const onMessage = (evt: MessageEvent) => {

      const data = JSON.parse(evt.data) as ServerToClientMessage

      if (data.type === "conversationUpdate") {

        // Only start displaying once there are at least two messages
        // This aligns with comment generation, which requires at least two messages
        if (data.conversation.length < 2) {
          return
        }

        setMessages(data.conversation)

        const firstMessage = data.conversation[0]

        if (firstMessage === undefined) { return }

        // Identify first user, to determine message color
        setFirstUser(firstMessage.participantID)

      }

    }

    socket.addEventListener("message", onMessage)

    return () => {
      socket.removeEventListener("message", onMessage)
    }

  }, [socket])

  return (
    <div className={styles.chatRoom}>
      <MessageList userId={(userId || firstUser)} messages={messages} />
    </div>
  );
}
