import type PartySocket from "partysocket";
import { useEffect, useRef, useState } from "react";
import { P5View } from "./p5/P5View";
import { type IRefPhaserGame, PhaserContainer } from "./phaser/PhaserContainer";
import { GameMessageBox } from "./components/GameMessageForm";
import type { ServerToClientMessage } from "../party/shared";
import { ChatRoom } from "./components/ChatRoom";
import styles from "./SwiperClient.module.css";
import sharedStyles from "./shared.module.css";

export const SwiperClient: React.FC<{ socket: PartySocket }> = ({ socket }) => {

  //  References to the PhaserGame component (game and scene are exposed) taken from phaser-react example
  const phaserRef = useRef<IRefPhaserGame | null>(null);

  const [faceIsVisible, setFaceIsVisible] = useState(false);
  const [mouthIsOpen, setMouthIsOpen] = useState(false);

  const [onboarded, setOnboarded] = useState(false);
  const [currentMessage, setCurrentMessage] = useState<string>("");

  const [allowedToParticipate, setAllowedToParticipate] = useState<boolean | null>(null);

  // https://stackoverflow.com/questions/60059165/better-way-to-execute-useeffect-when-boolean-changes-in-one-direction
  const prevMouthIsOpen = useRef(mouthIsOpen);

  useEffect(() => {

    if (phaserRef.current === null || phaserRef.current.game === null) { return }

    if (mouthIsOpen !== prevMouthIsOpen.current) {

      const game = phaserRef.current.game

      if (game) {

        game.events.emit("mouth-change", mouthIsOpen);

      };

    }

    prevMouthIsOpen.current = mouthIsOpen

  }, [mouthIsOpen])

  useEffect(() => {

    const onMessage = (evt: MessageEvent) => {

      const msg = JSON.parse(evt.data) as ServerToClientMessage

      if (msg.type === "currentMessageReset") {

        const { participantId } = msg
        const userId = participantId

        // Reset if this user just submitted a message
        if (userId === socket.id) {
          setCurrentMessage("")
        }

      }

      if (msg.type === "requestToJoinResponse") {

        setAllowedToParticipate(msg.accepted)

      }

      if (msg.type === "passwordRequested") {

        const password = prompt("Please enter the password to join the game")

        if (password === null) {
          socket.send(`{ "type": "requestToJoin", "joinType": "observer" }`)
        } else {
          socket.send(`{ "type": "requestToJoin", "joinType": "participant", "password": "${password}" }`)
        }

      }

    }

    socket.send(`{ "type": "requestToJoin", "joinType": "participant" }`)

    socket.addEventListener("message", onMessage)

    return () => {
      socket.removeEventListener("message", onMessage)
    }

  }, [socket])

  useEffect(() => {

    if (phaserRef.current === null || phaserRef.current.game === null) { return }

    if (faceIsVisible === false) {
      console.log("Pausing game")
      phaserRef.current.game.scene.pause("swipe-scene")
    } else {
      console.log("Resuming game")
      phaserRef.current.game.scene.resume("swipe-scene")
    }

  }, [faceIsVisible])

  return (
    <>
      <div className={styles.swiperClient}>
        {/* Here, we use mediapipe (via an invisible p5 instance)to read mouth state from webcam, which is fed into the Phaser game. */}
        <P5View setMouthIsOpen={setMouthIsOpen} setMouthIsVisible={setFaceIsVisible} />
        <div className={styles.swiperChatInterface + " " + sharedStyles.shadowBox}>
          <ChatRoom socket={socket} userId={allowedToParticipate ? socket.id : undefined} />
          {
            allowedToParticipate === true
            && onboarded === true
            && <GameMessageBox currentMessage={currentMessage} />
          }
        </div>
        {allowedToParticipate === null && <div id="requesting-to-join">Requesting to join...</div>}
        {allowedToParticipate === false && <div id="requesting-to-join">Sorry, the game is full.</div>}
        {allowedToParticipate === true && <>
          <div id="mouth-not-visible" style={{
            display: (faceIsVisible ? "none" : "block"),
            backgroundColor: "rgba(255, 255, 255)",
            borderRadius: "10px",
            border: "2px solid black",
            position: "absolute",
            width: "65%",
            top: "20%",
            padding: "2%",
          }}>
            <h1>No face detected<br />😶‍🌫️<br />Hold your phone out like you're taking a selfie<br />😀🤳</h1>
          </div>
          <div className={styles.gameContainer} style={{ display: (faceIsVisible ? "block" : "none") }}>
            <PhaserContainer
              ref={phaserRef}
              socket={socket}
              setCurrentMessage={setCurrentMessage}
              setOnboarded={setOnboarded}
            />
          </div>
        </>}
      </div>
    </>
  );
}