import type PartySocket from "partysocket";
import Phaser from "phaser";
import type { ClientToServerMessage, NextOption, ServerToClientMessage } from "../../party/shared";
import { Deck } from "./Deck";
import { EventBus } from "./EventBus";

export const serifFont = "Rubik"

export class SwipeScene extends Phaser.Scene {

  private deck: Deck
  private socket: PartySocket = null!

  private ended = false

  preload() {
    this.load.image('arrow', 'assets/arrow.png')
  }

  constructor() {

    super({
      key: 'swipe-scene'
    });

    this.deck = new Deck(this, (option) => this.selectOption(option))

  }

  create() {

    navigator.wakeLock?.request("screen") // Prevent device (e.g. phone) from sleeping

    // get the partysocket from where we stored it in the registry
    this.socket = this.game.registry.get('socket')

    // kick things off by requesting the first set of options
    this.requestNextOptions()

    this.socket.addEventListener("message", (evt) => {

      // parse messages from the server

      const msg = JSON.parse(evt.data) as ServerToClientMessage

      if (msg.type === "nextOptions") {

        this.receiveOptions(msg.nextOptions)

      }
      else if (msg.type === "endConversation") {

        // IF we havent' already been ghosted, get ghosted
        if (this.ended === false) {
          this.ghosted()
        }

      }
      else if (msg.type === "conversationUpdate") {

        this.requestNextOptions()

      }

    })

    this.game.events.on('mouth-change', (mouthOpened: boolean) => {

      if (mouthOpened) {
        this.onMouthOpen()
      } else {
        this.onMouthClose()
      }

    })

    this.deck.onCreate()

  }

  /**
   * Type-safe wrapper around sending a message
   * @param msg The message to send
  */
  send(msg: ClientToServerMessage) {

    // Don't send messages if the conversation has ended
    if (this.ended) { return }

    this.socket.send(JSON.stringify(msg));

  }

  receiveOptions(options: NextOption[]) {

    // When we receive options from the LLM/server, add them to the deck
    this.deck.addOptions(options, true)

  }

  requestNextOptions() {
    this.send({ type: "requestNextOptions" })
  }

  selectOption(option: NextOption) {
    // Send message to server
    this.send({ type: "selectOption", option: option[0] })

    // Send message to React
    EventBus.emit('option-selected', option)

    // Request next options from server
    this.requestNextOptions()
  }

  onMouthOpen() { }

  onMouthClose() { }

  ghosted() {

    // When the other person leaves, we play a little animation
    // in the style of GTA's "Wasted" screen, saying "Ghosted"

    this.ended = true

    const rectLarge = this.add.rectangle(
      this.cameras.main.width / 2,
      this.cameras.main.height / 2,
      this.cameras.main.width,
      this.cameras.main.height,
      0x000000,
      0.0
    )
      .setOrigin(0.5, 0.5)
      .setAlpha(0)
      .setDepth(998)


    const rectBanner =
      this.add.rectangle(
        this.cameras.main.width / 2,
        this.cameras.main.height / 2,
        this.cameras.main.width,
        this.cameras.main.height * 0.2,
        0x000000,
        0.5
      )
        .setOrigin(0.5, 0.5)
        .setAlpha(0)
        .setDepth(999)

    const text =
      this.add.text(
        this.cameras.main.width / 2,
        this.cameras.main.height / 2,
        "ghosted",
        {
          color: 'red',
          fontSize: this.cameras.main.height * 0.1 + 'px',
          fontFamily: serifFont,
          stroke: 'black',
          fontStyle: 'bold',
          strokeThickness: 5
        }
      )
        .setOrigin(0.5, 0.5)
        .setAlpha(0)
        .setDepth(1000)

    this.tweens.add({
      duration: 100,
      targets: [rectBanner, text],
      alpha: 1,
    })

    this.tweens.add({
      duration: 100,
      targets: [rectLarge],
      alpha: 0.2,
    })

    this.tweens.setGlobalTimeScale(0.1)

  }

}