
import type PartySocket from 'partysocket';
import React, { useEffect } from 'react';
import type { AdminToServerMessage, ServerToClientMessage } from '../party/shared';
import styles from "./AdminClient.module.css"

export const AdminClient: React.FC<{ socket: PartySocket }> = ({ socket }) => {

  const [adminPassword, setAdminPassword] = React.useState<string>("");
  const [adminAuthorised, setAdminAuthorised] = React.useState<boolean>(false);
  const [userPasswordState, setUserPasswordState] = React.useState<string | null>(null);

  useEffect(() => {

    const password = prompt("Enter admin password") ?? ""
    setAdminPassword(password)

  }, [])

  useEffect(() => {

    const message: AdminToServerMessage = {
      type: 'adminAuth',
      password: adminPassword,
    }

    socket.send(JSON.stringify(message));

  }, [adminPassword])

  const handleReset = (requirePassword: boolean) => {

    const message: AdminToServerMessage = {
      type: 'adminReset',
      password: adminPassword,
      requirePassword,
    }

    socket.send(JSON.stringify(message));

  };

  useEffect(() => {

    const onMessage = (evt: MessageEvent) => {

      const data = JSON.parse(evt.data) as ServerToClientMessage

      if (data.type === "passwordChange") {

        setUserPasswordState(data.password)

      }

      if (data.type === "adminAuthResponse") {

        setAdminAuthorised(data.accepted)

      }

    }

    socket.addEventListener("message", onMessage)

    return () => {
      socket.removeEventListener("message", onMessage)
    }

  }, [socket])

  return (
    <div className={styles.adminWrapper}>
      {adminAuthorised === false && <div>Unauthorised</div>}
      {adminAuthorised === true && <>
        <div>You are an admin</div>
        <div className={styles.adminPanel}>
          <button className={`${styles.adminButton} ${styles.withPassword}`} onClick={() => handleReset(true)}>
            RESET - WITH PASSWORD
          </button>
          <button className={`${styles.adminButton} ${styles.noPassword}`} onClick={() => handleReset(false)}>
            RESET - NO PASSWORD
          </button>
        </div>
        <div>User Password:<br />{userPasswordState ?? "none"}</div>
      </>}
    </div>
  );
};