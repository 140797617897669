import { serifFont, type SwipeScene } from "../game"
import { Card, type CardParams } from "./Card"

/**
 * Create a special instance of a card for the start of the game
 */

export class TutorialCard extends Card {

  private arrow1: Phaser.GameObjects.Image

  private tutorialText1: Phaser.GameObjects.Text
  private topText: Phaser.GameObjects.Text

  constructor(scene: SwipeScene, p: CardParams) {

    super(scene, p)

    this.setOption(["Tutorial", -4])

    this.arrow1 = scene.add.image(0, 0, 'arrow')

    this.tutorialText1 = scene.add.text(0, 0, "😮 Open mouth\nwhile face\nis here", {

      fontFamily: serifFont,
      fontSize: '1.4rem',
      color: '#' + this.trimColour.toString(16),
      // fontStyle: "bold",
      align: "center",

    })

    this.topText = scene.add.text(0, 0, "You select words by\nopening your mouth.", {

      fontFamily: serifFont,
      fontSize: '1.6rem',
      color: '#' + this.trimColour.toString(16),
      // fontStyle: "bold",
      align: "center",

    })

    this.add(this.arrow1)
    this.add(this.tutorialText1)
    this.add(this.topText)

    this.cardText.setVisible(false)

  }

  activate(onSuccess: () => void) {

    this.topText.updateText()
    this.tutorialText1.updateText()

    // Do some layout stuff that needed the scene to load first i think
    let { x, y } = this.firstLine.getCenter()

    this.arrow1
      .setOrigin(1, 0.5)
      .setScale(0.2 * this.rect.displayHeight / this.arrow1.width)
      .setDepth(5)
      .setAngle(-105)
      .setPosition(x, y);

    ({ x, y } = this.arrow1.getTopLeft())

    this.tutorialText1
      .setOrigin(0.5, 0)
      .setPosition(x, y)

    this.topText
      .setOrigin(0.5, 0.5)
      .setPosition(0, -this.scene.cameras.main.height * 0.2);

    ({ x, y } = this.topText.getBottomCenter())

    // Recursively keep activating the cursor until user succeeds
    this.resetCursor()

    this.activateCursor({
      onFailure: () => {
        this.activate(onSuccess)
      },
      onSuccess: () => {
        this.swipeRight(onSuccess)
      },
      delay: 1000,
    })

  }

}