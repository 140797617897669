.swiperClient {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100vw;
  height: 100vh;
}

.swiperChatInterface {
  position: fixed;
  display: flex;
  left: 0px;
  top: 0px;
  z-index: -10;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.gameContainer {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}