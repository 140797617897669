.commentSection {
  height: 100%;
  width: 60%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.bottomLeft {
  align-self: flex-start;
  margin-left: 1rem;
}

.bottomRight {
  align-self: flex-end;
  margin-right: 1rem;
}

.topRight {
  align-self: flex-end;
  /* margin-right: 1rem; */
}

.commentHeader {
  display: flex;
  /* justify-content: flex-end; */
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background-color: #ffffff;
  border-bottom: 1px solid #d1d5db;
  order: 1;
}

.commentFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
  background-color: #ffffff;
  border-top: 1px solid #d1d5db;
  order: 3;
}

.headerSpacer {
  width: 100%;
  height: 0.05rem;
}

.commentsList {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  overflow-y: auto;
  background-color: #ffffff;
  flex-grow: 1;
  order: 2;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.commentsList::-webkit-scrollbar {
  display: none;
}

.commentItem {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding: 0.5rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  margin-bottom: 0.75rem;
}

.commentText {
  font-size: medium;
}

.profilePicture img {
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
}

.bottom {
  height: 0.05rem;
}

.animatedHeader {
  max-width: 70%;
  /* width: 200px; */
  align-self: center;
  margin-left: 1rem;
}