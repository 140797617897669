/**
 * This file is adapted from the official Phaser React template.
 */

import type PartySocket from 'partysocket';
import { Game } from 'phaser';
import { forwardRef, useEffect, useLayoutEffect, useRef } from 'react';
import { EventBus } from './EventBus';
import styles from './PhaserContainer.module.css'
import { SwipeScene } from './game';
import type { NextOption } from '../../party/shared';

export interface IRefPhaserGame {
    game: Phaser.Game | null;
    scene: Phaser.Scene | null;
}

interface IProps {
    socket: PartySocket,
    setCurrentMessage: React.Dispatch<React.SetStateAction<string>>
    setOnboarded: React.Dispatch<React.SetStateAction<boolean>>
}

export const PhaserContainer = forwardRef<IRefPhaserGame, IProps>(function PhaserGame({ socket, setCurrentMessage, setOnboarded }, ref) {

    const game = useRef<Phaser.Game | null>(null!);

    useLayoutEffect(() => {

        if (game.current === null) {

            // Launch a new Phaser game, using our SwipeScene
            game.current = new Game({
                type: Phaser.WEBGL,
                width: window.innerWidth,
                height: window.innerHeight * 0.8,
                parent: styles.gameInnerContainer,
                transparent: true,
                scene: [
                    SwipeScene,
                ]
            });

            game.current.input.mouse?.disableContextMenu() // Disable right click
            // Register the partysocket with the game registry, so it can be called from anywhere within phaser
            game.current.registry.set<PartySocket>('socket', socket)

            // Idk what this is but it was in the phaser react starter template
            if (typeof ref === 'function') {
                ref({ game: game.current, scene: null });
            } else if (ref) {
                ref.current = { game: game.current, scene: null };
            }

        }

        // if the react component is unmounted, destroy the game
        return () => {
            if (game.current) {
                game.current.destroy(true);
                if (game.current !== null) {
                    game.current = null;
                }
            }
        }
    }, [ref]);

    const handleOptionSelected = (option: NextOption) => {
        setCurrentMessage(prevMsg => prevMsg + option[0]);
    }

    const handleOnboarded = () => {
        setOnboarded(true)
    }

    // Example of how to listen to events from Phaser
    useEffect(() => {
        EventBus.on('option-selected', handleOptionSelected);
        EventBus.on('onboarding-complete', handleOnboarded);
        return () => {
            EventBus.removeListener('option-selected', handleOptionSelected);
            EventBus.removeListener('onboarding-complete', handleOnboarded);
        }
    }, [ref]);

    return (
        <div id={styles.gameInnerContainer}></div>
    );

});
