.chatRoom {
  width: 100%;
  height: 100%;
  background-color: #f3f4f6;
  align-self: center;
  flex-grow: 1;
  font-size: medium;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
}